import { Button, Card, Col, InputNumber, Row, Space, Table, message, Modal, Select } from 'antd';
import { formatNumber } from 'egenie-common';
import type { BaseData, PaginationData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import { action, computed, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import qs from 'qs';
import React from 'react';
import type { ReceiptDetailItem } from './interface';
import styles from './subRoutes.less';
import { programme } from './index';

const max = 99999;

class Store {
  constructor() {
    const params = qs.parse(location.href.split('?')[1] || '') as { wmsReceiveOrderId: string; ownerName: string; warehouseName: string; warehouseId: string; wmsReceiveOrderNo: string; ownerId: string; };
    this.wmsReceiveOrderId = params.wmsReceiveOrderId || '';
    this.ownerName = params.ownerName || '';
    this.warehouseName = params.warehouseName || '';
    this.warehouseId = params.warehouseId || '';
    this.wmsReceiveOrderNo = params.wmsReceiveOrderNo || '';
    this.ownerId = params.ownerId || '';
    this.getData();
    this.getWarehouseAreaData();
  }

  private wmsReceiveOrderId = '';

  private warehouseId = '';

  private wmsReceiveOrderNo = '';

  private ownerId = '';

  public ownerName = '';

  public warehouseName = '';

  @action private clearWarehouseBinData = () => {
    this.dataSource.forEach((item) => {
      this.warehouseBinData.forEach((warehouseBinId) => {
        item[warehouseBinId.value] = 0;
      });
    });
  };

  @observable public warehouseAreaId: number = undefined;

  @observable.ref public warehouseAreaData = [];

  @action public handleWarehouseAreaChange = (warehouseAreaId) => {
    this.warehouseAreaId = warehouseAreaId;

    this.clearWarehouseBinData();

    this.getWarehouseShelf();
  };

  @action private getWarehouseAreaData = () => {
    request<BaseData<Array<{ warehouseAreaId: number; warehouseAreaName: string; warehouseAreaType: number; }>>>({ url: `/api/cloud/baseinfo/rest/warehouse/area/queryWarehouseAreaType/${this.warehouseId}` })
      .then((info) => this.warehouseAreaData = (info.data || []).map((item) => ({
        ...item,
        value: item.warehouseAreaId,
        label: item.warehouseAreaName,
      })));
  };

  @observable public warehouseShelfId: number = undefined;

  @observable.ref public warehouseShelfData = [];

  @action public handleWarehouseShelfChange = (warehouseShelfId) => {
    this.warehouseShelfId = warehouseShelfId;

    this.clearWarehouseBinData();

    this.getWarehouseBinData();
  };

  @action private getWarehouseShelf = () => {
    this.warehouseShelfId = undefined;
    this.warehouseShelfData = [];

    this.warehouseBinId = [];
    this.warehouseBinData = [];

    request<BaseData<{[key: string]: string; }>>({
      url: '/api/cloud/baseinfo/rest/warehouse/shelf/queryWarehouseShelfByArea',
      method: 'post',
      data: {
        warehouseId: this.warehouseId,
        warehouseAreaId: this.warehouseAreaId,
      },
    })
      .then((info) => this.warehouseShelfData = Object.entries(info.data || {})
        .map(([
          value,
          label,
        ]) => ({
          value,
          label,
        })));
  };

  @observable public warehouseBinId: number[] = [];

  @observable.ref public warehouseBinData = [];

  @action public handleWarehouseBinChange = (warehouseBinId) => {
    this.warehouseBinId = warehouseBinId || [];
  };

  @action private getWarehouseBinData = () => {
    this.warehouseBinId = [];
    this.warehouseBinData = [];

    request<BaseData<Array<{ id: number; serialNo: number | string; }>>>({
      url: '/api/cloud/baseinfo/rest/warehouse/bin/checkUnusedBin',
      method: 'post',
      data: {
        warehouseId: this.warehouseId,
        warehouseAreaId: this.warehouseAreaId,
        warehouseShelfId: this.warehouseShelfId,
        ownerId: this.ownerId,
      },
    })
      .then(action((info) => {
        this.warehouseBinData = (info.data || []).map((item) => ({
          value: item.id,
          label: item.serialNo,
        }));
      }));
  };

  @observable public isSearch = false;

  @action private getData = () => {
    this.isSearch = true;
    this.selectedRowKeys = [];
    request<PaginationData<ReceiptDetailItem>>({
      method: 'post',
      url: '/api/cloud/wms/rest/receiveOrder/pageQueryDetail',
      data: {
        wmsReceiveOrderId: this.wmsReceiveOrderId,
        page: 1,
        pageSize: 999999999,
      },
    })
      .then((info) => this.dataSource = (info?.data?.list || []).map((item, index) => ({
        ...item,
        _index: index + 1,
      })))
      .finally(() => this.isSearch = false);
  };

  @action public handleCancel = () => {
    //
  };

  @action public handleSave = () => {
    if (!this.warehouseAreaId) {
      message.warning('请选择货区');
      return;
    }

    if (!this.warehouseShelfId) {
      message.warning('请选择货架');
      return;
    }

    if (!this.warehouseBinId.length) {
      message.warning('请选择库位');
      return;
    }

    if (!this.selectedRowKeys.length) {
      message.warning('请选择表格项目');
      return;
    }

    const data = {
      realArea: this.warehouseAreaData.find((item) => item.value === this.warehouseAreaId)?.warehouseAreaType != 5,
      wmsReceiveOrderId: this.wmsReceiveOrderId,
      wmsReceiveOrderNo: this.wmsReceiveOrderNo,
      warehouseId: this.warehouseId,
      ownerId: this.ownerId,
      list: (() => {
        const result = [];
        this.dataSource.filter((item) => this.selectedRowKeys.includes(item.wmsReceiveOrderDetailId))
          .forEach((item) => {
            this.warehouseBinId.forEach((warehouseBinId) => {
              result.push({
                wmsReceiveOrderDetailId: item.wmsReceiveOrderDetailId,
                skuId: item.skuId,
                warehouseAreaId: this.warehouseAreaId,
                warehouseShelf: this.warehouseShelfId,
                warehouseBinId,
                num: formatNumber(item[warehouseBinId]),
                productId: item.productId,
                productNo: item.productNo,
              }
              );
            });
          });
        return result;
      })(),
    };
    Modal.confirm({
      okText: '确定',
      cancelText: '取消',
      content: '确认审核入库?',
      onOk: () => request({
        method: 'POST',
        url: '/api/cloud/wms/rest/receiveOrder/createStockIn',
        data,
      })
        .then(() => {
          message.success('审核入库成功');

          // 刷新
          programme?.gridModel?.onRefresh();
          this.handleCancel();
        }),
    });
  };

  @computed
  public get totalReceiveActualNum() {
    return this.dataSource.reduce((prev, current) => prev + formatNumber(current.totalReceiveRealNum) + formatNumber(current.totalReceiveDefectiveNum), 0);
  }

  @computed
  public get totalStockInNum() {
    return this.dataSource.reduce((prev, current) => prev + this.warehouseBinId.reduce((_prev, key) => _prev + formatNumber(current[key]), 0), 0);
  }

  @observable public selectedRowKeys: number[] = [];

  @action public onChange = (selectedRowKeys: number[]): void => {
    this.selectedRowKeys = selectedRowKeys;
  };

  @observable public dataSource: ReceiptDetailItem[] = [];

  @computed
  public get columns() {
    const result = [
      {
        title: '序号',
        dataIndex: '_index',
        width: 50,
      },
      {
        title: '商品编码',
        dataIndex: 'productNo',
        width: 250,
      },
      {
        title: '图片',
        dataIndex: 'picUrl',
        width: 60,
        render: (url) => (
          <ImgFormatter value={url}/>
        ),
      },
      {
        title: '颜色',
        dataIndex: 'colorType',
        width: 100,
      },
      {
        title: '尺寸',
        dataIndex: 'sizeType',
        width: 100,
      },
      {
        title: '已收货正',
        dataIndex: 'totalReceiveRealNum',
        width: 100,
      },
      {
        title: '已收货次',
        dataIndex: 'totalReceiveDefectiveNum',
        width: 100,
        render: (text) => {
          return (
            <span style={{
              color: '#f2270a',
              fontWeight: 400,
            }}
            >
              {text}
            </span>
          );
        },
      },
    ];

    this.warehouseBinId.forEach((warehouseBinId) => {
      result.push({
        title: `库位-${this.warehouseBinData.find((item) => item.value === warehouseBinId)?.label || ''}`,
        dataIndex: `${warehouseBinId}`,
        width: 100,

        // @ts-ignore
        render: (_, row) => {
          return (
            <Observer>
              {
                () => (
                  <InputNumber
                    max={max}
                    min={0}
                    onChange={(value) => row[warehouseBinId] = formatNumber(value)}
                    value={formatNumber(row[warehouseBinId])}
                  />
                )
              }
            </Observer>
          );
        },
      });
    });

    result.push({
      title: '累计入库正',
      dataIndex: 'totalStockInRealNum',
      width: 100,
    },
    {
      title: '累计入库次',
      dataIndex: 'totalStockInDefectiveNum',
      width: 100,
      render: (text) => {
        return (
          <span style={{
            color: '#f2270a',
            fontWeight: 400,
          }}
          >
            {text}
          </span>
        );
      },
    });
    return result;
  }
}

@observer
export default class extends React.Component {
  public store = new Store();

  render() {
    const {
      handleCancel,
      handleSave,
      columns,
      dataSource,
      selectedRowKeys,
      onChange,
      ownerName,
      totalReceiveActualNum,
      isSearch,
      warehouseName,
      totalStockInNum,
      warehouseAreaData,
      warehouseAreaId,
      handleWarehouseAreaChange,
      warehouseShelfId,
      warehouseShelfData,
      handleWarehouseShelfChange,
      warehouseBinId,
      warehouseBinData,
      handleWarehouseBinChange,
    } = this.store;
    return (
      <div className={styles.container}>
        <Card style={{
          marginBottom: 20,
          flexShrink: 0,
        }}
        >
          <Row justify="space-between">
            <Col
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
            >
              <Space align="center">
                <span className={styles.goBackArrow}>
                  {'<'}
                </span>
                <span className={styles.goBackTitle}>
                  生成入库单
                </span>
              </Space>
            </Col>
            <Col>
              <Space align="center">
                <Button onClick={handleCancel}>
                  取消
                </Button>
                <Button
                  className="ghost-bg-btn"
                  disabled={dataSource.length < 1}
                  ghost
                  onClick={handleSave}
                >
                  同意并审核入库
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
        <Card style={{
          marginBottom: 20,
          flexShrink: 0,
        }}
        >
          <header className={styles.header}>
            <div className={styles.item}>
              <span/>
              基础信息
            </div>
          </header>
          <section className={styles.headContent}>
            <span>
              仓库名称:&nbsp;
              {warehouseName}
            </span>
            <span>
              供应商:&nbsp;
              {ownerName}
            </span>
            <span>
              实际收货数量:&nbsp;
              {totalReceiveActualNum}
            </span>
            <span>
              实际入库数量:&nbsp;
              {totalStockInNum}
            </span>
          </section>
        </Card>
        <Card style={{ flexGrow: 1 }}>
          <header className={styles.header}>
            <div className={styles.item}>
              <span/>
              商品明细
            </div>
          </header>
          <div className={styles.selectContainer}>
            <div className={styles.selectItem}>
              <span>
                货区:&nbsp;&nbsp;
              </span>
              <Select
                onChange={handleWarehouseAreaChange}
                options={warehouseAreaData}
                placeholder="请选择货区"
                value={warehouseAreaId}
              />
            </div>
            <div className={styles.selectItem}>
              <span>
                货架:&nbsp;&nbsp;
              </span>
              <Select
                onChange={handleWarehouseShelfChange}
                options={warehouseShelfData}
                placeholder="请选择货架"
                value={warehouseShelfId}
              />
            </div>
            <div className={styles.selectItem}>
              <span>
                库位:&nbsp;&nbsp;
              </span>
              <Select
                mode="multiple"
                onChange={handleWarehouseBinChange}
                options={warehouseBinData}
                placeholder="请选择库位"
                value={warehouseBinId}
              />
            </div>
          </div>
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={isSearch}
            pagination={false}
            rowKey="wmsReceiveOrderDetailId"
            rowSelection={{
              selectedRowKeys,
              onChange,
            }}
            scroll={{ x: 1500 }}
            size="small"
            sticky
          />
        </Card>
      </div>
    );
  }
}
